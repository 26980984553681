import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import OV from './components/openvidu/ov3'
import Analytics from './Analytics';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter,Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    {/* <App /> */}
    <Routes>
        <Route path="/" element={<App />} />
        {/* <Route path="/ov3" element={<OV />} />
        <Route path="/analytics" element={<Analytics />} /> */}
      </Routes>
    </BrowserRouter>
  
  </React.StrictMode>
);

reportWebVitals();
