
export const QUERY_KEYS = {
  user: 'user',
  templates: 'templates',
  metaverses: 'metaverses',
  profile: 'profile',
  collaborators: 'collaborators',
  sharedMetaverses: 'sharedMetaverses',
  settings: 'settings',
  invitations: 'invitations',
}

export const DASHBOARD_SECTION_TYPES = {
  TEMPLATES: 'templates',
  SHARED: 'shared',
  MYWORLDS: 'myworlds',
}
//5.6.0 
//5.5.9

export const configFishNet = {
  // "APIAddress": "https://api.app.enterprise.holofair.io/AddressablesCatalog?platform=WebGL",
  // "ServerSettings": {
  //     "MaxClients": 2000,
  //     "ServerPort": 8345,
  //     "ClientPort": 443,
  //     "ServerHostAddress": "enterprise.holofair.io",
  //     "ClientTargetAddress": "enterprise.holofair.io"
  // }

  
    // "LoadPath": "https://app.enterprise.holofair.io",
    // "APIAddress": "https://api.app.enterprise.holofair.io/AddressablesCatalog?platform=WebGL",
    // "ServerSettings": {
    //     "MaxClients": 2000,
    //     "ServerPort": 8345,
    //     "ClientPort": 443,
    //     "ServerHostAddress": "enterprise.holofair.io",
    //     "ClientTargetAddress": "enterprise.holofair.io"
    // }

    
    // "UUID": "TestAdminUUID",
    // "LoadPath": "https://app.enterprise.holofair.io/ServerData",
    // "APIAddress": "https://api.app.enterprise.holofair.io/AddressablesCatalog?platform=WebGL",
    // "ServerSettings": {
    //     "MaxClients": 2000,
    //     "ServerPort": 8345,
    //     "ClientPort": 443,
    //     "ServerHostAddress": "enterprise.holofair.io",
    //     "ClientTargetAddress": "enterprise.holofair.io"
    // }


    
    // "UUID": "TestUUID",
    // "APIAddress": "https://gdrfa.enterprise.holofair.io/api/AddressablesCatalog/get?platform=webgl",
    // "ServerSettings": {
    //     "MaxClients": 2000,
    //     "ServerPort": 7770,
    //     "ClientPort": 443,
    //     "ServerHostAddress": "enterprise.holofair.io",
    //     "ClientTargetAddress": "enterprise.holofair.io"
    // }


    "UUID": "087b0b36-e21e-426c-92ce-c1e74bf0b0b4",
    "APIAddress": "https://gdrfa.enterprise.holofair.io/api/AddressablesCatalog/get?platform=webgl",
    "AnalyticsStartedAPI": "https://gdrfa.enterprise.holofair.io/api/interactions/add/started",
    "AnalyticsEndedAPI": "https://gdrfa.enterprise.holofair.io/api/interactions/update/ended",
    "AnalyticsImmediateAPI": "https://gdrfa.enterprise.holofair.io/api/interactions/add/immediate",
    "ServerSettings": {
        "MaxClients": 2000,
        "ServerPort": 7770,
        "ClientPort": 7770,
        "ServerHostAddress": "gdrfa.enterprise.holofair.io",
        "ClientTargetAddress": "gdrfa.enterprise.holofair.io"
    }
  

};
//6.6.7
const BuildVersion = '6.9.0'
export const UNITY_URLS = {
  // dataUrl: "WebGL/Build/WebGL.data.unityweb",
  // frameworkUrl: "WebGL/Build/WebGL.framework.js.unityweb",
  // loaderUrl: "WebGL/Build/WebGL.loader.js",
  // codeUrl: "WebGL/Build/WebGL.wasm.unityweb",
  // streamingAssetsUrl: "WebGL/StreamingAssets"

  dataUrl: `https://cdn.holofair.io/build/2022.3.27f1/${BuildVersion}/Build/WebGL.data.unityweb`,
  frameworkUrl: `https://cdn.holofair.io/build/2022.3.27f1/${BuildVersion}/Build/WebGL.framework.js.unityweb`,
  loaderUrl: `https://cdn.holofair.io/build/2022.3.27f1/${BuildVersion}/Build/WebGL.loader.js`,
  codeUrl: `https://cdn.holofair.io/build/2022.3.27f1/${BuildVersion}/Build/WebGL.wasm.unityweb`,
  streamingAssetsUrl: `https://cdn.holofair.io/build/2022.3.27f1/${BuildVersion}/StreamingAssets`
 
}




